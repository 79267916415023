<template>
  <div
    ref="navbar"
    v-click-outside="isSmallView ? () => null : globalNavStore.closeNav"
    class="global-nav top-0 right-0 left-0 overflow-x-hidden md:overflow-x-visible text-white w-full z-40"
    :class="[
      { 'nav-open': isMobileNavActive },
      { 'large-scale-nav': largeScale }
    ]"
  >
    <notification-bar />
    <div
      class="flex flex-col h-full w-full flex-1 bg-int-grey-01 relative md:flex-row md:flex-wrap md:mx-auto md:px-6 md:max-w-screen-2xl xl:px-12"
    >
      <div
        class="global-nav-header flex relative items-center z-50 bg-int-grey-01"
      >
        <nuxt-link
          :to="{ name: 'international' }"
          class="block px-4 md:px-0 py-3 md:py-4"
        >
          <img
            class="h-9 md:h-14"
            src="/TAFE-international-logo.svg"
            data-not-lazy
            alt="Go to international home"
          />
        </nuxt-link>
        <int-global-nav-menu-button />
      </div>
      <div class="global-nav-content md:contents overscroll-none">
        <!-- TO DO: will update the route name here once international course search result page complete -->
        <int-global-nav-search-bar v-if="route.name !== 'course-search'" />
        <hr class="hidden md:block md:w-full md:border-int-grey-03" />
        <div
          class="flex flex-col flex-grow mt-auto bg-int-grey-01 relative md:flex-row md:flex-wrap md:-mx-3 md:z-10"
          :class="{ 'overflow-x-hidden	overflow-y-scroll': isMobileNavActive && isSmallView }"
        >
          <div class="flex-grow">
            <ul
              class="nav-lv-0 list-unstyled flex flex-col md:flex-row flex-wrap -mt-px"
            >
              <li
                v-for="(topLevel, i) in navItems"
                :key="i"
                :class="{ active: i === globalNavStore.activeNav[0] }"
                class="md:z-60"
              >
                <int-global-nav-list-item
                  :item="topLevel"
                  :index="i"
                  class="text-white"
                />
                <div
                  v-if="topLevel.children"
                  ref="megamenu"
                  class="mega-menu md:absolute md:hidden md:shadow md:top-full md:left-0 md:right-0 md:bg-int-grey-02 md:min-h-40"
                  :style="{ maxHeight: navData.megaMenuHeight }"
                >
                  <int-global-navbar-list
                    :items="topLevel.children"
                    :index="1"
                    class="nav-lv-1 bg-int-grey-02 text-white md:w-1/3 lg:w-1/4 md:py-4"
                    :class="{ active: i === globalNavStore.activeNav[0] }"
                    :active-child="activeThirdLevel"
                    :style="{ maxHeight: navData.megaMenuHeight }"
                  />
                  <template v-for="(secondLevel, j) in topLevel.children">
                    <int-global-navbar-list
                      v-if="
                        secondLevel.children && secondLevel.children.length > 0
                      "
                      :key="j"
                      :items="secondLevel.children"
                      :index="2"
                      :split="viewport.isLessThan('lg') ? 18 : 12"
                      class="text-black bg-white font-semibold md:py-4"
                      :class="{
                        active: j === activeThirdLevel,
                        'mobile-active': j === globalNavStore.activeNav[1]
                      }"
                    />
                  </template>
                </div>
              </li>
            </ul>
          </div>
          <div class="flex-shrink bg-int-grey-01 text-white md:ml-auto">
            <int-service-navbar />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import GlobalNavLink = models.stores.siteConfig.GlobalNavLink
import { nextTick } from 'vue'
import { useGlobalNavStore } from '~/stores/globalNav'
import { useIntSiteConfigStore } from '~/stores/intSiteConfig'

const viewport = useViewport()
const route = useRoute()
const globalNavStore = useGlobalNavStore()
const siteConfigStore = useIntSiteConfigStore()
const deviceScaleRatio = useDeviceScaleRatio()

const navData = reactive({
  parents: [] as Number[],
  activePage: [] as Number[],
  megaMenuHeight: '100%'
})

const navbar = ref<HTMLElement | null>(null)

const isMobileNavActive = computed(() => globalNavStore.isMobileNavActive)

const largeScale = computed(() => {
  return deviceScaleRatio.largeScale.value
})

const navItems = computed(() => {
  return siteConfigStore.config?.globalNav || []
})

const isSmallView = computed(() => {
  return viewport.isLessThan('md')
})

const activeThirdLevel = computed(() => {
  if (globalNavStore.activeNav.length > 1) {
    return globalNavStore.activeNav[1]
  } else if (navData.activePage[0] === globalNavStore.activeNav[0]) {
    return navData.activePage[1] as number
  } else {
    const navItemKey = globalNavStore.activeNav[0] as keyof GlobalNavLink[]
    const parent = globalNavStore.activeNav.length
      ? (<GlobalNavLink>navItems.value[navItemKey])?.children
      : 0
    return (
      parent && parent.findIndex((e) => e.children && e.children.length > 0)
    )
  }
})

watch(
  globalNavStore.activeNav,
  () => {
    setActiveParent(navItems.value)
  },
  { deep: true }
)

watch(route, (newVal: unknown) => {
  if (newVal) {
    globalNavStore.setMobileNav(false)
    globalNavStore.closeNav()
  }
})

onMounted(() => {
  nextTick(() => {
    setActiveParent(navItems.value)
    navData.megaMenuHeight = `calc(100vh - ${navbar.value?.offsetHeight}px)`
  })
})

function setActiveParent(items: GlobalNavLink[], level = 0) {
  for (let index = 0; index < items.length; index++) {
    const item = items[index]
    if (item.to === route.fullPath || item.to?.name === route.name) {
      navData.parents[level] = index
      navData.activePage = [navData.parents[0], navData.parents[1]]
      break
    }
    navData.parents[level] = index
    if (item.children && item.children.length) {
      setActiveParent(item.children, level + 1)
    }
  }
}
</script>

<style lang="postcss">
@media (max-width: 767px) {
  .global-nav.nav-open {
    @apply fixed bottom-0 overflow-y-auto overflow-x-hidden;
  }

  .global-nav button {
    @apply outline-none;
  }

  .global-nav .global-nav-content {
    @apply flex flex-col h-0;
    /* this needs to be calculated with JS */
    transform: translateY(-140vh);
    transition: transform 0.6s ease;
  }

  .global-nav.nav-open .global-nav-content {
    height: 100%;
    transform: translateY(0);
  }

  /* Slide in nav levels */
  .nav-lv-1,
  .nav-lv-2 {
    @apply absolute inset-0 overflow-y-auto z-50 transition-all duration-700;
    transform: translateX(100%);
  }
  .nav-lv-1 > ul {
    @apply bg-int-grey-02 h-full
  }
  .nav-lv-2 > ul {
    @apply bg-white
  }
  .nav-lv-0 > li.active .nav-lv-1.active,
  .nav-lv-0 > li.active .nav-lv-2.mobile-active,
  .log-in-nav.active {
    @apply overflow-visible;
    transform: translateX(0);
  }
}

@media (min-width: 768px) {
  .nav-lv-0 > li.active > .nav-item {
    @apply bg-int-grey-02;
  }

  li.active .mega-menu {
    @apply flex;
  }

  .nav-lv-1,
  .nav-lv-2 {
    @apply overflow-y-auto;
  }

  .nav-lv-2 {
    @apply hidden;
  }
  .nav-lv-2.active {
    @apply flex flex-1;
  }
  .nav-lv-2.active > ul {
    flex: 0 1 50%;
  }
}

@media (min-width: 1280px) {
  .nav-lv-2.active > ul {
    flex-basis: 33%;
  }
}

.large-scale-nav {
  position: relative !important;
}
</style>
